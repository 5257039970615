import * as React from "react";

//Components
import Layout from "../components/layout/layout";
import Container from "../components/container/container";
import Link from "../components/link/link";

//CSS

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Container>
        <article>
          <section>
            <h1>Sell creatively</h1>
            <p>
              The most powerful headless ecommerce platform for modern brands,
              startups, and agencies.
            </p>
          </section>
          <section>
            <div>
              <h2>Made for growth</h2>
              <p>
                Until now, brands looking to stand out have been using a
                patchwork of apps for legacy platforms or building from scratch.
                Swell enables you to create sophisticated solutions faster,
                without having to think about infrastructure or maintenance.
              </p>
              <Link to="/">Read the case studies</Link>
            </div>
            <div>
              <h2>Commerce without compromise</h2>
              <p>
                Free your storefront from your backend. With headless themes and
                extensive options for modelling products, you can offer unique
                customer experiences anywhere.
              </p>
              <Link to="/">Tell me more</Link>
            </div>
          </section>
        </article>
      </Container>
    </Layout>
  );
};

export default IndexPage;
